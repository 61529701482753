'use client';

import { useApi } from '@prismo-io/core';
import type { SWRArgs } from '@prismo-io/schemas';
import useSWRMutation from 'swr/mutation';
import { checkVerificationCode } from './check-verification-code';
export const useCheckVerificationCode = (email: string) => {
  const {
    client
  } = useApi();
  return useSWRMutation(['check-verification-code', email], (key, {
    arg
  }: SWRArgs<{
    code: string;
  }>) => checkVerificationCode(client, {
    _email: email,
    _code: arg.code
  }));
};