'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { Button, OtpInput } from '@prismo-io/design-system';
import { useSendVerificationCode as useSendVerificationCodeOnboarding } from '@prismo-io/feature-auth/components/forms/sign-up';
import useRunOnce from '@prismo-io/utils/client/use-run-once';
import { type FC, type ReactNode, useCallback } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { VerificationCodeSchema, type VerificationCodeSchemaT } from './schema';
import { useCheckVerificationCode } from './use-check-verification-code';
import { useSendVerificationCode } from './use-send-verification-code';
export type VerificationCodeFormProps = {
  onSubmit: SubmitHandler<VerificationCodeSchemaT>;
  email: string;
  firstName?: string;
  defaultValues?: Partial<VerificationCodeSchemaT>;
  app?: 'ONBOARDING' | 'TOOLS';
  submitBtns?: ReactNode;
};
export const VerificationCodeForm: FC<VerificationCodeFormProps> = props => {
  const {
    onSubmit: onNext,
    email,
    firstName = undefined,
    defaultValues = {},
    app = 'TOOLS',
    submitBtns = undefined
  } = props;
  const {
    trigger: sendVerificationCode
  } = useSendVerificationCode(email);
  const {
    trigger: sendVerificationCodeOnboarding
  } = useSendVerificationCodeOnboarding({
    email,
    firstName
  });
  const {
    trigger: checkVerificationCode
  } = useCheckVerificationCode(email);
  useRunOnce({
    fn: () => {
      app === 'ONBOARDING' ? sendVerificationCodeOnboarding() : sendVerificationCode();
    }
  });
  const formMethods = useForm<VerificationCodeSchemaT>({
    mode: 'all',
    resolver: zodResolver(VerificationCodeSchema()),
    shouldUseNativeValidation: false,
    defaultValues: {
      verificationCode: '',
      ...defaultValues
    }
  });
  const {
    control,
    handleSubmit,
    formState: {
      isValid,
      isSubmitSuccessful
    },
    setError
  } = formMethods;
  const onSubmit: SubmitHandler<VerificationCodeSchemaT> = useCallback(data => {
    return checkVerificationCode({
      code: data.verificationCode
    }, {
      onSuccess: () => {
        onNext(data);
      },
      onError: error => {
        setError('verificationCode', {
          message: error.message,
          type: 'validate'
        });
      }
    });
  }, [checkVerificationCode, onNext]);
  return <FormProvider {...formMethods} data-sentry-element="FormProvider" data-sentry-component="VerificationCodeForm" data-sentry-source-file="form.tsx">
      <Stack spacing={4} component="form" onSubmit={handleSubmit(onSubmit)} noValidate data-sentry-element="Stack" data-sentry-source-file="form.tsx">
        <Stack direction="row" justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="form.tsx">
          <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="form.tsx">
            <Trans data-sentry-element="Trans" data-sentry-source-file="form.tsx">Validez votre adresse mail :</Trans>
          </Typography>
        </Stack>

        <Typography data-sentry-element="Typography" data-sentry-source-file="form.tsx">
          <Trans data-sentry-element="Trans" data-sentry-source-file="form.tsx">
            Renseignez le code à 6 chiffres reçu sur votre adresse email pour
            valider votre inscription.
          </Trans>
        </Typography>

        <Stack spacing={1} alignItems="center" justifyContent="center" data-sentry-element="Stack" data-sentry-source-file="form.tsx">
          <OtpInput control={control} name="verificationCode" valueLength={6} data-sentry-element="OtpInput" data-sentry-source-file="form.tsx" />

          <Button variant="text" color="gray" size="small" onClick={() => sendVerificationCode()} data-sentry-element="Button" data-sentry-source-file="form.tsx">
            <Trans data-sentry-element="Trans" data-sentry-source-file="form.tsx">Renvoyer le mail de vérification</Trans>
          </Button>
        </Stack>

        {!!submitBtns ? submitBtns : <Stack alignItems="center">
            <Button variant="contained" type="submit" disabled={!isValid || isSubmitSuccessful} formNoValidate>
              Valider
            </Button>
          </Stack>}
      </Stack>
    </FormProvider>;
};