'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, t } from '@lingui/macro';
import { Button, Stack, ToggleButton, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToggleButtonGroupInput } from '@prismo-io/design-system';
import { OrganizationActivity, OrganizationVolume } from '@prismo-io/schemas';
import { useUsermaven } from '@prismo-io/tracking';
import { type FC, type ReactNode, useEffect, useMemo } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import type { UseCaseSchemaT } from '../use-case/schema';
import { VolumeSchema, type VolumeSchemaT } from './schema';
export type VolumeFormProps = {
  defaultValues?: Partial<VolumeSchemaT>;
  onSubmit: SubmitHandler<VolumeSchemaT>;
  submitBtns?: ReactNode;
  useCases: UseCaseSchemaT['useCases'];
};
export const VolumeForm: FC<VolumeFormProps> = props => {
  const {
    useCases,
    onSubmit: onNext,
    defaultValues = {},
    submitBtns = undefined
  } = props;
  const formMethods = useForm<VolumeSchemaT>({
    mode: 'all',
    resolver: zodResolver(VolumeSchema(useCases)),
    defaultValues: {
      talentVolume: undefined,
      ...defaultValues
    }
  });
  const {
    control,
    handleSubmit,
    formState: {
      isValid,
      isSubmitSuccessful
    },
    watch
  } = formMethods;
  const usermaven = useUsermaven();
  const onSubmit: SubmitHandler<VolumeSchemaT> = data => {
    usermaven.track('volume_chosen', {
      usages: data.talentVolume
    });
    return onNext(data);
  };
  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);
  const question = useMemo(() => {
    if (useCases.includes(OrganizationActivity.Enum.HR_DEVELOPMENT)) {
      return t`Quel est le nombre de collaborateurs ?`;
    }
    if (useCases.includes(OrganizationActivity.Enum.TRAINING)) {
      return t`Combien d’étudiants suivez-vous ?`;
    }
    if (useCases.includes(OrganizationActivity.Enum.RECRUITMENT)) {
      return t`Quel est le nombre de vos recrutements par an ?`;
    }
    if (useCases.includes(OrganizationActivity.Enum.COACHING)) {
      return t`Quel est le nombre de vos coachés par an ?`;
    }
    return t`Quel est le volume de votre activité ?`;
  }, [useCases]);
  const options = useMemo(() => {
    if (useCases.includes(OrganizationActivity.Enum.HR_DEVELOPMENT)) {
      return [{
        label: t`Moins de 50`,
        value: OrganizationVolume.Enum.LESS_THAN_50
      }, {
        label: t`Entre 50 et 250`,
        value: OrganizationVolume.Enum.BETWEEN_50_AND_250
      }, {
        label: t`Plus de 250`,
        value: OrganizationVolume.Enum.GREATER_THAN_250
      }];
    }
    if (useCases.includes(OrganizationActivity.Enum.TRAINING)) {
      return [{
        label: t`Moins de 50`,
        value: OrganizationVolume.Enum.LESS_THAN_50
      }, {
        label: t`Entre 50 et 250`,
        value: OrganizationVolume.Enum.BETWEEN_50_AND_250
      }, {
        label: t`Plus de 250`,
        value: OrganizationVolume.Enum.GREATER_THAN_250
      }];
    }
    if (useCases.includes(OrganizationActivity.Enum.RECRUITMENT)) {
      return [{
        label: t`Moins de 10`,
        value: OrganizationVolume.Enum.LESS_THAN_10
      }, {
        label: t`Entre 10 et 50`,
        value: OrganizationVolume.Enum.BETWEEN_10_AND_50
      }, {
        label: t`Entre 50 et 250`,
        value: OrganizationVolume.Enum.BETWEEN_50_AND_250
      }, {
        label: t`Plus de 250`,
        value: OrganizationVolume.Enum.GREATER_THAN_250
      }];
    }
    if (useCases.includes(OrganizationActivity.Enum.COACHING)) {
      return [{
        label: t`Moins de 10`,
        value: OrganizationVolume.Enum.LESS_THAN_10
      }, {
        label: t`Entre 10 et 50`,
        value: OrganizationVolume.Enum.BETWEEN_10_AND_50
      }, {
        label: t`Entre 50 et 250`,
        value: OrganizationVolume.Enum.BETWEEN_50_AND_250
      }, {
        label: t`Plus de 250`,
        value: OrganizationVolume.Enum.GREATER_THAN_250
      }];
    }
    if (useCases.includes('OTHERS')) {
      return [{
        label: t`Moins de 10`,
        value: OrganizationVolume.Enum.LESS_THAN_10
      }, {
        label: t`Entre 10 et 50`,
        value: OrganizationVolume.Enum.BETWEEN_10_AND_50
      }, {
        label: t`Entre 50 et 250`,
        value: OrganizationVolume.Enum.BETWEEN_50_AND_250
      }, {
        label: t`Plus de 250`,
        value: OrganizationVolume.Enum.GREATER_THAN_250
      }];
    }
  }, [useCases]);
  const isUpMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return <FormProvider {...formMethods} data-sentry-element="FormProvider" data-sentry-component="VolumeForm" data-sentry-source-file="form.tsx">
      <Stack spacing={4} component="form" onSubmit={handleSubmit(onSubmit)} data-sentry-element="Stack" data-sentry-source-file="form.tsx">
        <Stack direction="row" justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="form.tsx">
          <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="form.tsx">{question}</Typography>
        </Stack>
        <ToggleButtonGroupInput control={control} name="talentVolume" orientation={isUpMd ? 'horizontal' : 'vertical'} disabled={isSubmitSuccessful} color="primary" fullWidth exclusive data-sentry-element="ToggleButtonGroupInput" data-sentry-source-file="form.tsx">
          {options?.map(opt => <ToggleButton key={opt.value} value={opt.value}>
              <Trans>{opt.label}</Trans>
            </ToggleButton>)}
        </ToggleButtonGroupInput>
        {!!submitBtns && submitBtns}
        {isValid && !submitBtns && <Stack alignItems="center">
            <Button variant="contained" type="submit" disabled={!isValid}>
              <Trans>Valider</Trans>
            </Button>
          </Stack>}
      </Stack>
    </FormProvider>;
};