'use client';

import { useApi } from '@prismo-io/core';
import useSWRMutation from 'swr/mutation';
import { sendVerificationCode } from './send-verification-code';
export const useSendVerificationCode = (email: string) => {
  const {
    client
  } = useApi();
  return useSWRMutation(['send-verification-code', email], key => sendVerificationCode(client, email));
};